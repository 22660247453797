.Wrapper {
  margin-top: 2rem;
}
.fullHeight {
  height: 70%;
}
.title {
  font-weight: 700;
  font-size: 20x;
  line-height: 23px;
}

.LoginWrapper {
  text-align: center;

  .LoginImage {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.RecaptchaContainer {
  text-align: center;
  display: inline-block;
}

.links {
  color: #01857a !important;
  cursor: pointer;
}

.links:hover {
  font-weight: 900;
}
.paragrahp {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  /* or 120% */
  text-align: center;
  color: #01807a;
}
