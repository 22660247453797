.BoxBubble {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  background-color: #d9f6ef;
  border-radius: 5px;
  position: relative;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

    flex: 1 1 calc(25% - 16px); 
  min-width: calc(25% - 16px);

  &.Disabled::before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    background-color: #ffffff;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .Bidder {
    margin: 0;
    color: #33cccc;
    display: flex;
    flex-direction: row;
    align-items: center;

    .Icon {
      color: #ffd722;
      height: 15px;
      width: 15px;
    }

    h5 {
      margin: 0;
      text-transform: uppercase;
    }
  }

  .BubbleHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: .5rem;

    .Title {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  .BidDetails {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;

    .DetailRow {
      display: flex;
      flex-direction: row;
      padding: 0.1rem 0.3rem;
      font-size: 0.8rem;
      color: #676767;
      font-weight: 500;
      // border-bottom: 1px solid #e3e3e3;

      .Label {
        text-align: left;
        width: 100%;
        position: relative;
        align-items: center;
        display: flex;

        &:after {
          content: " ";
          position: absolute;
          height: 122%;
          width: 1px;
          // background-color: #e3e3e3;
          right: 0;
          bottom: -2px;
        }
      }

      .Value {
        text-align: right;
        width: 100%;
        text-transform: capitalize;
      }
    }
  }

  .BubbleFooter {
    display: flex;
    flex-direction: row;
    margin-top: .5rem;

    .Left {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .Right {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: end;
    }
  }
}

.ProgressBar {
  display: flex;
  flex-direction: row;

  .Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #979797;
    padding: .5rem;
  }

  .Track {
    display: flex;
    flex-direction: column;

    p {
      color: #979797;
      font-size: .8rem;
      margin: 5px 0;
    }

    .Bar {
      width: 100%;
      height: .4rem;
      background-color: #fff;
      border-radius: 5px;
      position: relative;

      span {
        width: 10%;
        background-color: #81dfdf;
        position: absolute;
        left: 0;
        top: 0;
        height: .4rem;
        border-radius: 5px;
      }
    }
  }
}

.PulseStart {
  color: gold;
  display: inline-block;
  animation: pulse 2s infinite;
  transform-origin: center center;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

.CaptionDialog {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #4e5a64;
}