.linearProgressBox {
  display: flex;
  align-items: center;
}
.circularProgressBox {
  position: relative;
  display: inline-flex;
}
.linearProgressBoxContainer {
  min-width: 35;
}
.circularProgressBoxContainer {
  position: "absolute";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: "flex";
  align-items: center;
  justify-content: center;
}

.alignText {
  text-align: center;
}
.cardOfertTitle {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  text-shadow: 0.5px 0px;
}

.cardOfertDescription {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-shadow: 0.5px 0px;
}

.typografy {
  font-family: "Roboto";
}

.highlightTitle {
  font-weight: bold;
  color: #33cccc;
}
.highlightParagrah {
  color: #01857a;
}
.cardDisabled {
  background-color: #e4e4e4 !important;
}
.stepCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stepCard:nth-child(2) {
  border: 2px solid blue;
  display: flex;
  flex-direction: column;
}

.FooterBox {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
}

.CaptionNext {
  color: gray;
}