$slow: #f15a24;
$medium-slow: #fdcd00;
$medium-hight: #a4c626;
$hight: #49af46;
.HomeWrapper {
  height: 100vh; /* Establece la altura al 100% del alto de la ventana del navegador */
}

.CaptionHeader {
  margin-bottom: 1rem;
}

.MainTitle {
  margin-bottom: -5px;
}

.MainCaption {
  margin-bottom: 0rem;
  color: gray;
}

.fullHeight {
  height: 70%;
}

.ChipAuto {
  border-radius: 5px !important;
  padding-top: 0.1rem !important;
  text-transform: uppercase !important;
  font-size: 0.7rem !important;
  height: 1.3rem !important;
  margin-left: 0.5rem !important;
}

.ChipYellow {
  border-radius: 5px !important;
  padding-top: 0.1rem !important;
  text-transform: uppercase !important;
  font-size: 0.7rem !important;
  height: 1.3rem !important;
  margin-left: 0.5rem !important;

  border-color: #bcac18 !important;
  color: #bcac18 !important;
}

.ChipGray {
  border-radius: 5px !important;
  padding-top: 0.1rem !important;
  text-transform: uppercase !important;
  font-size: 0.7rem !important;
  height: 1.3rem !important;
  margin-left: 0.5rem !important;

  border-color: gray !important;
  color: gray !important;
}

.ChipBrown {
  border-radius: 5px !important;
  padding-top: 0.1rem !important;
  text-transform: uppercase !important;
  font-size: 0.7rem !important;
  height: 1.3rem !important;
  margin-left: 0.5rem !important;

  border-color: #795548 !important;
  color: #795548 !important;
}

.NameClient {
  display: block;
  font-weight: 600;
  margin: 0;
}

.NameChip {
  display: block;
  // border-radius: 4px !important;
  // font-size: 0.7rem !important;
  // padding: 0 !important;
  // height: 19px !important;
}

.BoxPaper {
  background-color: #fff;
}

.HomeWrapper {
  margin-top: 2rem;

  .HeaderBox {
    text-align: center;
    margin-top: 1rem;
  }
}

.cellHiglights {
  font-weight: bold !important;
  color: black;
  font-size: 16px;
}
.customCell {
  border: "1px solid black";
}
.colorScoringHightValue {
  color: #a1cc47;
}
.colorScoringLowValue {
  color: #fdcd00;
}

// $disabled-color: rgba(0, 0, 0, 0.38);
$disabled-color: #f2f2f2;
$colibid-color: #01857a;

.chooseBidBtn {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.customTable {
  display: block;
  overflow: auto;
  width: 100%;
  border-collapse: collapse;
  zoom: 80%;
  font-weight: bold;
  font-size: large;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  tr {
    th,
    td {
      text-align: center;
      padding: 0.5rem;
      border-bottom: 0.5px solid $disabled-color;
      border-right: 0.5px solid $disabled-color;
    }

    th {
      text-align: center;
      color: $colibid-color;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        svg.deactive {
          visibility: hidden;
        }
      }
    }

    td {
      @media screen and (min-width: 600px) {
        font-size: calc(10px + (18 - 10) * ((100vw - 600px) / (1600 - 300)));
      }
    }

    td:last-child,
    th:last-child {
      border-right: none;
    }
  }
}

.slow {
  color: $slow;
}
.medium-slow {
  color: $medium-slow;
}
.medium-hight {
  color: $medium-hight;
}
.hight {
  color: $hight;
}
.info {
  font-family: "Roboto";
  font-style: normal;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .container {
    height: 60%;
  }
}
