.bottomNav {
  // padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  // outline: 1px solid red;
  z-index: 1;// should i put over 9000! 🥬
}

.ButtonCTA {
  color: #ffffff !important;
}