@import "./themes/scss/media-queries.scss";

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  background-color: #70707005;
  // height: 100vh;
  // height: -webkit-fill-available;
}

#content {
  // padding-top: 1rem;

  &.not-auth-layout {
    padding-top: 0;
    position: relative;
    // height: calc(98vh - 64px);
    // padding-top: unset;
  }

  &.main-layout {
    height: calc((var(--vh, 1vh) * 100) - 128px);
    overflow-y: auto;
    position: relative;

    &.bidder {
      height: calc((var(--vh, 1vh) * 100) - 64px);
    }

    &.client {
      padding-top: 0;
      // the footer navbar height
      padding-bottom: calc(72px + 1rem);
      // the --vh variable will be set from js taking the window innerHeight * 0.01
    }
  }
}

.mt-6 {
  margin-top: 3rem;
}

.phone-selector svg {
  max-width: 2rem;
}

.MuiGrid-root {
  position: relative;
}

.MuiTooltip-tooltip {
  background-color: rgb(0 0 0 / 80%) !important;
  font-weight: 400 !important;
}

.MuiTooltip-arrow {
  color: rgb(0 0 0 / 80%) !important;
}

.containerPinCode {
  display: flex !important;
}

.containerPinCode input[type="text"]:focus {
  outline: none !important;
  box-shadow: 0 0 10px #01857a;
}

.bid-bubbles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.bid-bubbles-header {
  margin-top: 1rem;
  display: flex;
}

.bid-bubbles-filters {
  padding-left: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    margin-right: 0.6rem;
  }

  button {
    margin: 0 0.1rem;
    background-color: #fff;
    border-radius: 3px;
    color: #01857a;
    border: 1px solid #01857a;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.5s ease;
    margin-bottom: 3px;

    // &:hover,
    &.active {
      background-color: #01857a;
      color: #fff;
      transition: all 0.5s ease;
    }
  }
}

@media (max-width: 768px) {
  .bid-bubbles {
    grid-template-columns: 1fr;
  }

  .bid-bubbles-header:not(.w-fixed) {
    flex-direction: column;
    position: fixed;
    background-color: #fff;
    top: 2rem;
    width: 100%;
    height: 5rem;
    z-index: 3;
    left: 0;
    padding: 1rem;
    border-bottom: 1px solid #e5e2e2;
  }

  .stepper-bubbles {
    margin-top: 8rem !important;
  }

  .bid-bubbles-filters:not(.w-fixed) {
    padding-left: 0rem;
    margin-top: .5rem;
  }
}

.bid-bubbles-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  align-items: center;

  h1 {
    margin: 0;
    color: #01857a;
    font-weight: 500;
    margin-bottom: 3px;
  }
}

.bid-bubbles-counter {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.8rem;
  color: #555555;
}

.next-btn {
  background-color: #e5f287 !important;
  color: #555555 !important;
}

.next-btn:disabled {
  background-color: #555555 !important;
  color: #fff !important;
  opacity: 0.3;
}

.pulseStart {
  color: gold;
  display: inline-block;
  animation: pulse 2s infinite;
  transform-origin: center center;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.bid-bubbles-footer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.text-since {
  color: #33cccc;
}

.text-until {
  color: #000;
}
